import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <Container>
        <span>Copyright &copy; 2019 <a href="https://immunearch.com/" target="_blank" rel="noopener noreferrer">Immune Arch Inc.</a> All rights reserved.</span>
        <span className="pull-right">For research purposes only.</span>
      </Container>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
