export default {
  items: [
    {
      name: 'Home',
      url: '/home',
      icon: 'icon-home',
    },
    {
      name: 'Projects',
      url: '/projects',
      icon: 'icon-docs',
    },
    {
      name: 'Samples',
      url: '/samples',
      icon: 'icon-chemistry',
    },
    {
      name: 'Tasks',
      url: '/tasks',
      icon: 'icon-list',
    },
    {
      name: 'Studies',
      url: '/studies',
      icon: 'icon-chart',
    },
    {
      name: 'Reports',
      url: '/reports',
      icon: 'icon-notebook',
    },
  ],
};
