import { API, Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import './App.scss';

// Containers
import { DefaultLayout } from './containers';

// Pages
import { Login, Page404, Page500, Register } from './views/Pages';

import analysisNav from './views/Studies/Analysis/_nav.js';
// import { renderRoutes } from 'react-router-config';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      demoIsCreating: false,
      isAuthenticated: false,
      isAuthenticating: true,
      authenticatedUser: null,
      authenticatedUserAttributes: null
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    } catch (e) {
      console.error(e);
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({isAuthenticating: false});
  }

  userHasAuthenticated = async authenticated => {
    // Set user in state when authenticated
    if (authenticated) {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        this.setState({authenticatedUser});

        try {
          const authenticatedUserAttributes = await new Promise((res, rej) => {
            this.state.authenticatedUser.getUserAttributes((e, result) => e ? rej(e) : res(result));
          });
          this.setState({authenticatedUserAttributes});

          this.loadDemos();
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      this.setState({
        authenticatedUser: null,
        authenticatedUserAttributes: null
      });
    }

    this.setState({isAuthenticated: authenticated});
  }

  loadDemos = async () => {
    this.setState({demoIsCreating: true});
    const demo = await API.get('general', '/users/populate');
    console.log(demo);
    this.setState({demoIsCreating: false});
  };

  render() {
    const childProps = {
      demoIsCreating: this.state.demoIsCreating,
      isAuthenticated: this.state.isAuthenticated,
      authenticatedUser: this.state.authenticatedUser,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      <HashRouter>
        <Switch>
          <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} props={childProps} />
          <UnauthenticatedRoute exact path="/register" name="Registration Page" component={Register} props={childProps} />
          <UnauthenticatedRoute exact path="/404" name="404 Error" component={Page404} props={childProps} />
          <UnauthenticatedRoute exact path="/500" name="500 Error" component={Page500} props={childProps} />
          <AuthenticatedRoute path="/studies/:id/analysis" name="Analysis" component={() => <DefaultLayout subnav={analysisNav} />} props={childProps} />
          <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} props={childProps} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
