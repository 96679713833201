export default {
  items: [
    {
      name: 'Basic Statistics',
      url: 'basic-statistics',
      icon: 'icon-calculator',
    },
    {
      name: 'V/J Segment Usage',
      url: 'segment-usage',
      icon: 'fa fa-bar-chart',
    },
    {
      name: 'Segment Cluster',
      url: 'segment-cluster',
      icon: 'icon-share',
    },
    {
      name: 'CDR3 length',
      url: 'cdr3-length',
      icon: 'icon-chart',
    },
    {
      name: 'V-J junctions',
      url: 'vj-junctions',
      icon: 'fa fa-random',
    },
    {
      name: 'Repertoire overlap',
      url: 'repertoire-overlap',
      icon: 'icon-people',
    },
    {
      name: 'Diversity Estimation',
      url: 'diversity-estimation',
      icon: 'icon-pie-chart',
    },
    {
      name: 'Miscellaneous',
      url: 'miscellaneous',
      icon: 'icon-options',
    }
  ],
};
