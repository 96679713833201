import { Auth } from 'aws-amplify';

import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, Container, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { AppHeaderDropdown } from '@coreui/react';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.props.userHasAuthenticated(false);
    this.props.history.push('/login');
  }

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <Container>
        <Col sm={3}></Col>
        <Col sm={6} className="text-center">
          <Link to={'/dashboard'} className="navbar-brand">
            <h3 className="brand-text">Immune Arch</h3>
          </Link>
        </Col>
        <Col sm={3}>
          <Nav navbar>
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
                direction="down">
                <DropdownToggle nav>
                <img src={'assets/img/avatars/user.jpg'} className="img-avatar" alt="" /> 
                {this.props.authenticatedUser
                  ?  (<React.Fragment>{this.props.authenticatedUser.username} <small className="icon-arrow-down"></small></React.Fragment>)
                  : ''}
                </DropdownToggle>
                <DropdownMenu right style={{ right: 'auto' }}>
                  {/* <DropdownItem><i className="fa fa-user"></i> Profile</DropdownItem>
                  <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem> */}
                  <DropdownItem onClick={this.handleLogout}><i className="fa fa-lock"></i> Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>
          </Nav>
        </Col>
      </Container>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
