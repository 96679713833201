export default {
  Auth: {
    mandatorySignIn: true,
    region: "us-east-2",
    userPoolId: "us-east-2_IEdY0oK8z",
    identityPoolId: "us-east-2:bdcbf522-30b1-439b-a20b-3fc038e773c9",
    userPoolWebClientId: "23m42k9ehpm7ht88n2c9mmgjtc"
  },
  Storage: {
    AWSS3: {
      region: "us-east-2",
      bucket: "userdata-dev.immudx.com",
      identityPoolId: "us-east-2:bdcbf522-30b1-439b-a20b-3fc038e773c9"
    },
  },
  API: {
    endpoints: [
      {
        name: "general",
        endpoint: "https://r4k4utq6p5.execute-api.us-east-2.amazonaws.com/dev",
        region: "us-east-2"
      },
    ]
  }
}