import { Auth } from "aws-amplify";
import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      newUser: null,
      error: null,
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    };
  }

  registrationIsValid() {
    return (
      this.state.username.length > 0 &&
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleRegistrationSubmit = async event => {
    event.preventDefault();

    if (!this.registrationIsValid) {
      this.state.error = `Please check your input.`;
      return false;
    }
  
    this.setState({ isLoading: true });
  
    try {
      const newUser = await Auth.signUp({
        username: this.state.username,
        password: this.state.password,
        attributes: {
          email: this.state.email
        }
      });
      
      this.setState({newUser});
      } catch (e) {
      console.error(e);
    }
  
    this.setState({ isLoading: false });
  }
  
  navigatToLogin = event => {
    event.preventDefault();
    this.props.history.push("/login");
  }

  render() {
    return (
      <div className="register">
        {this.state.newUser === null
          ? this.renderRegistrationForm()
          : this.renderConfirmation()}
      </div>
    );
  }

  renderRegistrationForm() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <Form onSubmit={this.handleRegistrationSubmit}>
                  <CardBody className="p-4">
                    <h1>Create your account</h1>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Username" autoComplete="username" id="username" value={this.state.username} onChange={this.handleChange} autoFocus />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Email" autoComplete="email" id="email" value={this.state.email} onChange={this.handleChange} />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder="Password" autoComplete="new-password" id="password" value={this.state.password} onChange={this.handleChange} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder="Repeat password" autoComplete="new-password" id="confirmPassword" value={this.state.confirmPassword} onChange={this.handleChange} />
                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md="4">
                        <Button block onClick={this.navigatToLogin}>Back to login</Button>
                      </Col>
                      <Col md="2"></Col>
                      <Col md="6">
                        <Button color="success" block>Create your account</Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  renderConfirmation() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <h1>Confirm your account</h1>
                  <p>Please check your email <strong>{this.state.email}</strong> and confirm your account. Afterwards return to the login page and sign in. </p>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col md="6"></Col>
                    <Col md="6">
                      <Button color="success" block onClick={this.navigatToLogin}>Login</Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Register;
