import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

import StudiesRoutes from './views/Studies/routes';

function Loading() {
  return <div>loading ...</div>;
}

const Home = Loadable({loader: () => import('./views/Home'), loading: Loading});
const Projects = Loadable({loader: () => import('./views/Projects'), loading: Loading});
const Samples = Loadable({loader: () => import('./views/Samples'), loading: Loading});
const Tasks = Loadable({loader: () => import('./views/Tasks'), loading: Loading});
const Clonotypes = Loadable({loader: () => import('./views/Clonotypes'), loading: Loading});
const Reports = Loadable({loader: () => import('./views/Reports'), loading: Loading});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Root', component: DefaultLayout },
  { path: '/home', name: 'Home', component: Home },
  { path: '/projects', name: 'Projects', component: Projects },
  { path: '/samples', exact: true, name: 'Samples', component: Samples },
  { path: '/samples/:id', name: 'Clonotypes', component: Clonotypes },
  { path: '/tasks', exact: true, name: 'Tasks', component: Tasks },
  ...StudiesRoutes,
  { path: '/reports', exact: true, name: 'Reports', component: Reports },
];
console.log(routes);

export default routes;
