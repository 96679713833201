import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import {
  AppFooter,
  AppHeader,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
// import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import DefaultNavigation from './DefaultNavigation';
import DefaultSubnav from './DefaultSubnav';

class DefaultLayout extends Component {
  render() {
    const subnav = this.props.subnav;
    return (
      <div className="app">
        <AppHeader>
          <DefaultHeader {...this.props} />
        </AppHeader>
        <div className="app-body">
          <nav className="navbar navbar-expand-lg">
            <Container>
              <DefaultNavigation navConfig={navigation} />
            </Container>
          </nav>
          <main className="main">
            {this.props.demoIsCreating
              ? <p className="text-center mt-1">Loading content, this might take a minute …</p>
              : (
                <Container>
                  <Row>
                    <Col md={subnav ? 3 : null}>
                      <DefaultSubnav navConfig={subnav} />
                    </Col>
                    <Col md={subnav ? 9 : 12}>
                      <Switch>
                        {routes.map((route, idx) => {
                            return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                                <route.component {...props} />
                              )} />)
                              : (null);
                          },
                        )}
                        <Redirect from="/" to="/home" />
                      </Switch>
                    </Col>
                  </Row>
                </Container>
                )}
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
