import React, { Component } from 'react';
import { Alert, Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Auth } from 'aws-amplify';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: null
    }
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.resetError();

    this.setState({
      [event.target.id]: event.target.value
    });
  }

  resetError() {
    if (this.state.error !== null) {
      this.setState({error: null});
    }
  }

  handleLoginSubmit = async event => {
    event.preventDefault();
  
    try {
      const result = await Auth.signIn(this.state.username, this.state.password);
      this.props.userHasAuthenticated(true);
      this.props.history.push('/dashboard');
      console.log(result);
    } catch (e) {
      console.error(e);
      this.setState({error: e.message});
    }
  }

  navigateToRegister = event => {
    this.props.history.push('/register');
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.handleLoginSubmit}>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input id="username" type="text" placeholder="Username" autoComplete="username" value={this.state.username} onChange={this.handleChange} />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input id="password" type="password" placeholder="Password" autoComplete="current-password" value={this.state.password} onChange={this.handleChange} />
                      </InputGroup>
                      <Alert color="danger" hidden={this.state.error === null}>{this.state.error}</Alert>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4" disabled={!this.validateForm()}>Login</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">Forgot password?</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Please contact info@immunearch.com for the registration code to sign up. </p>
                      <Button color="primary" className="mt-3" active onClick={this.navigateToRegister}>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
