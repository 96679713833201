import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
  return <div>loading ...</div>;
}

const Studies = Loadable({
  loader: () => import('./Studies'),
  loading: Loading,
});

const BasicStatistics = Loadable({
  loader: () => import('./Analysis/BasicStatistics'),
  loading: Loading,
});

const SegmentUsage = Loadable({
  loader: () => import('./Analysis/SegmentUsage'),
  loading: Loading,
});

const SegmentCluster = Loadable({
  loader: () => import('./Analysis/SegmentCluster'),
  loading: Loading,
});

const Cdr3Length = Loadable({
  loader: () => import('./Analysis/Cdr3Length'),
  loading: Loading,
});

const RepertoireOverlap = Loadable({
  loader: () => import('./Analysis/RepertoireOverlap'),
  loading: Loading,
});

const DiversityEstimation = Loadable({
  loader: () => import('./Analysis/DiversityEstimation'),
  loading: Loading,
});

const Junctions = Loadable({
  loader: () => import('./Analysis/Junctions'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { 
    path: '/studies',
    exact: true, 
    name: 'Studies', 
    component: Studies
  },
  { path: '/studies/:id/analysis/basic-statistics', name: 'Basic Statistics', component: BasicStatistics },
  { path: '/studies/:id/analysis/segment-usage', name: 'V/J Segment Usage', component: SegmentUsage },
  { path: '/studies/:id/analysis/segment-cluster', name: 'Segment Cluster', component: SegmentCluster },
  { path: '/studies/:id/analysis/cdr3-length', name: 'CDR3 Length', component: Cdr3Length },
  { path: '/studies/:id/analysis/repertoire-overlap', name: 'Repertoire Overlap', component: RepertoireOverlap },
  { path: '/studies/:id/analysis/diversity-estimation', name: 'Diversity Estimation', component: DiversityEstimation },
  { path: '/studies/:id/analysis/vj-junctions', name: 'V-J Junctions', component: Junctions },
  // { path: '/studies/:id/analysis', exact: true, name: 'Basic Statistics', component: BasicStatistics },
];

export default routes;
